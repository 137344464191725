.container {
  background: rgba(255, 255, 255, 0.4);
  border-radius: 30px;
  box-shadow: 0px 3px 15px -1px #000000;
  min-width: 300px;
  max-width: 670px;
  margin: 30px;
  opacity: 0.6;
  overflow: hidden;
  transition: all ease 0.5s;
}

.container:hover {
  opacity: 1;
  cursor: pointer;
  transform: scale(1.035);
}

.container h4 {
  font-size: 1.5rem;
  font-weight: 400;
}

.container h5 {
  font-size: 1.2rem;
  font-weight: 400;
}

@media (max-width: 450px) {
  .container {
    opacity: 1;
  }
}
