.item {
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0px 3px 15px -1px #000000;
  min-height: 500px;
  width: 400px;
  margin: 30px;
  overflow: hidden;
  position: relative;
  opacity: 0.6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: all ease 0.5s;
}

.item:hover {
  opacity: 1;
  transform: scale(1.035);
}

.head {
  display: flex;
  flex-direction: column;
  align-items: center;
}

img {
  width: 100%;
}

.title {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 20px;
}

hr {
  width: 60%;
  margin: 10px 0 10px 0;
}

.date {
  text-align: center;
  font-size: 1.2rem;
}

.description {
  text-align: center;
  padding: 10px;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  text-decoration: none;
  padding: 10px;
  font-size: 1.3rem;
  height: 65px;
  width: 100%;
  padding: 10px;
  color: #333 !important;
  background: rgba(255, 255, 255, 0.4);
  transition: ease 0.5s;
}

.btn:hover {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.8);
}

.btn i {
  margin-right: 7px;
}

@media (max-width: 450px) {
  .item {
    min-height: 480px;
    opacity: 1;
  }

  .btn {
    height: 70px;
    flex-direction: column;
    font-size: 1rem;
  }

  .btn i {
    transform: scale(1.5);
  }
}

@media (max-width: 850px) {
  .item {
    opacity: 1;
  }
}
