.container {
  background: #333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.title {
  background: #333;
  color: #dddddd;
  font-size: 2rem;
  font-weight: 300;
  padding: 50px;
}

.certificates {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1500px;
  margin: auto;
}
