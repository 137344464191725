.container {
  background: #333;
  width: 100%;
  padding: 20px 0 20px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.heading {
  background: #333;
  color: #dddddd;
  font-size: 2rem;
  font-weight: 300;
  text-align: center;
  padding-top: 20px;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
  cursor: pointer;
  transition: all ease 0.5s;
}

.item:hover {
  transform: scale(1.1);
}
