@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: #454545;
}

::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #333;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(170, 170, 170, 0.5);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(170, 170, 170, 1);
}
