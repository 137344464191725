.navbar {
  background: #aaa;
  position: fixed;
  top: -2rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-evenly;
  height: 50px;
  width: 50vw;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  box-shadow: 0px 3px 15px -1px #000000;
  opacity: 0.5;
  z-index: 100;
  animation: slide-up 300ms ease forwards;
}

.navbar:hover {
  animation: slide-down 300ms ease forwards;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-decoration: none;
  color: #222;
  pointer-events: fill;
  transition: all ease 0.5s;
}

.link:first-child {
  border-bottom-left-radius: 30px;
}
.link:nth-child(3) {
  border-bottom-right-radius: 30px;
}

.link:hover {
  background: #fff;
  pointer-events: all;
}

.custom-shape-divider-top {
  position: absolute;
  top: 99%;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 35px;
  z-index: -5;
}

.custom-shape-divider-top .shape-fill {
  fill: #aaa;
}

@media (max-width: 440px) {
  .navbar {
    width: 100%;
  }

  .custom-shape-divider-top {
    top: 95%;
  }

  .custom-shape-divider-top svg {
    height: 20px;
  }
}

@keyframes slide-down {
  0% {
    transform: translate(-50%, -6%);
    opacity: 0.5;
  }
  100% {
    transform: translate(-50%, 2rem);
    opacity: 1;
  }
}
@keyframes slide-up {
  0% {
    transform: translate(-50%, 2rem);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -6%);
    opacity: 0.5;
  }
}
