.tooltip {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 5px;
  background-color: #fff;
  animation: fade ease-in forwards 0.3s;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
