.container {
  display: flex;
  overflow: auto;
  height: 50%;
}

@media (min-width: 450px) {
  .container img {
    height: 80vh;
  }
}
