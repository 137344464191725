.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1500px;
  background: #454545;
  margin: auto;
}

.title {
  text-align: center;
  color: #dddddd;
  font-size: 2rem;
  font-weight: 300;
  margin: 20px 0 50px 0;
}
