.container {
  height: 100vh;
  position: relative;
  display: flex;
  border-bottom: 2px solid #aaa;
}

.left {
  background-color: #333333;
  /* background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23aaaaaa' fill-opacity='0.5' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E"); */
  height: 100vh;
  width: 50%;
  border-bottom: 2px solid #aaa;
}

.right {
  background-color: #454545;
  /* background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23aaaaaa' fill-opacity='0.45' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E"); */
  height: 100vh;
  width: 50%;
  border-bottom: 2px solid #aaa;
}

.title-box {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 10;
}

.title {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
}

.title h1 {
  color: #fff;
  font-size: 4rem;
  line-height: 80px;
}

.title h3 {
  color: #fff;
  font-size: 2rem;
  font-weight: 300;
  margin-top: 15px;
}

.title hr {
  width: 80%;
}

.links {
  display: flex;
  justify-content: center;
}

.links a {
  text-decoration: none;
  color: rgb(184, 184, 184);
  padding: 0 15px 0 15px;
  font-size: 1.2rem;
  transition: ease 0.5s;
}

.links a:hover {
  color: #fff;
}

.container > a {
  color: #fff;
  border: none;
  background-color: transparent;
  position: absolute;
  bottom: 50px;
  left: 50%;
  animation: slide 1.5s ease-in infinite;
}

.container > a:hover {
  cursor: pointer;
}

@keyframes slide {
  0% {
    transform: translate(-50%, 0);
  }
  50% {
    transform: translate(-50%, 20px);
  }
  100% {
    transform: translate(-50%, 0);
  }
}

@media (max-width: 920px) {
  .title-box {
    font-size: 1.5rem;
  }
}
